<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.title')"
              label-for="name"
            >
              <b-form-input
                id="title"
                :value="searchFields.title"
                @input="inputChange($event, 'title')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.customer_name')"
              label-for="customer_name"
            >
              <b-form-input
                id="title"
                :value="searchFields.customer_name"
                @input="inputChange($event, 'customer_name')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :can-change-status="false"
    >
      <template
        v-slot:custom_actions="{ item, generalActions }"
      >
        <b-link
          v-b-tooltip.hover.v-primary
          :title="$t('Details')"
          class="ml-1"
          :to="{ name: 'page-details', params: { resourceId: item.id } }"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-middle text-body"
          />
        </b-link>
      </template>
    </TableIndex>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resourceName: 'real-estate-views',
      singularName: 'real-estate-views',
      searchFields: {
        title: null,
        customer_name: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
